<template>
  <b-card no-body class="transparent">
    <b-card-header
      header-tag="header"
      v-b-toggle.accordion1
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'p-1']"
      role="tab"
    >
      <v-row>
        <v-col>
          <div class="pa-5 d-block">
            {{ $t("tablebee.lang_theme_colors") }}
          </div>
        </v-col>

        <v-col align="end" class="pa-5">
          <v-btn
            depressed
            color="success"
            @click="updateColor"
            :loading="loadig"
          >
            {{ $t("generic.lang_save") }}
          </v-btn>
        </v-col>
      </v-row>
    </b-card-header>

    <b-card-body>
      <v-row>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="main-card mb-3 card shadow-sm"
              >
                <div class="pa-0 ma-0 dropdown-menu-header">
                  <div class="card-header bg-info text-white">
                    {{ $t("generic.lang_slideshowFooterBackground") }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker
                    mode="hexa"
                    v-model="colors.slideshow_footer"
                    class="ma-0"
                    width="100%"
                    hide-canvas
                    show-swatches
                    swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t("generic.lang_slideshowFooterBackground") }} </span>
          </v-tooltip>
        </v-col>

        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="main-card mb-3 card shadow-sm"
              >
                <div class="pa-0 ma-0 dropdown-menu-header">
                  <div class="card-header bg-info text-white">
                    {{ $t("generic.lang_backgroundcolor") }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker
                    mode="hexa"
                    v-model="colors.background"
                    class="ma-0"
                    width="100%"
                    hide-canvas
                    show-swatches
                    swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t("generic.lang_backgroundcolor") }}</span>
          </v-tooltip>
        </v-col>

        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="main-card mb-3 card shadow-sm"
              >
                <div class="pa-0 ma-0 dropdown-menu-header">
                  <div class="card-header bg-info text-white">
                    {{ $t("generic.lang_productCardBackground") }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker
                    mode="hexa"
                    v-model="colors.product_card"
                    class="ma-0"
                    width="100%"
                    hide-canvas
                    show-swatches
                    swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t("generic.lang_productCardBackground") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

export default {
  name: "priceCheckColorsComponent",
  props: {
    colors: {
      type: Object,
      required: false,
      // default: () => {},
      default() {
        return {
          background: "",
          slideshow_footer: "",
          product_card: "",
        };
      },
    },
  },
  data() {
    return {
      loadig: false,
    };
  },
  methods: {
    updateColor() {
      this.loadig = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATE.COLOR, {
          templateID: this.$route.params.id,
          themeColor: JSON.stringify(this.colors),
        })
        .then((res) => {
          if (res.status === 200) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loadig = false;
        });
    },
  },
};
</script>

<style scoped>
.dropdown-menu-header {
  z-index: 1 !important;
}
</style>
