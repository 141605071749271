<template>
  <div>
    <page-title
      show-previous-button
      url-previous="/settings/cashierSettings/priceCheckTerminal"
      :heading="$t('settings.lang_priceCheckTerminalTemplate')"
      :subheading="$t('settings.lang_priceCheckTerminalTemplate')"
      :icon="icon"
    ></page-title>

    <div class="app-main__inner">
      <Template></Template>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import Template from "../../../components/settings/cashier/priceCheckTerminal/Template.vue";

export default {
  name: "PriceCheckTerminalTemplate",

  components: {
    PageTitle,
    Template,
  },

  data: () => ({
    icon: "pe-7s-monitor icon-gradient bg-tempting-azure",
  }),
};
</script>
