var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('b-card',{staticClass:"mb-1",class:[this.$vuetify.theme.dark ? 'dark-bg' : ''],attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('v-list-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.global-settings",modifiers:{"global-settings":true}}],staticClass:"ma-0",on:{"click":function($event){_vm.opened = !_vm.opened}}},[_c('v-list-item-title',{class:[
                this.$vuetify.theme.dark ? '' : '',
                this.opened ? 'primary--text text-wrap' : 'text-wrap',
              ]},[_vm._v(" "+_vm._s(this.$t("settings.lang_priceCheckInterface"))+" ")]),_c('v-list-item-action',[(!this.opened)?_c('v-icon',[_vm._v("keyboard_arrow_down")]):_vm._e(),(this.opened)?_c('v-icon',[_vm._v("keyboard_arrow_up")]):_vm._e()],1)],1)],1),_c('b-collapse',{attrs:{"visible":this.opened,"accordion":"global-settings","id":"global-settings","role":"tabpanel"}},[_c('b-card-body',{staticClass:"pa-0"},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"items",class:[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'colors' ? 'primary--text' : '',
                ],on:{"click":function($event){return _vm.handleClick('colors')}}},[_vm._v(" "+_vm._s(this.$t("tablebee.lang_theme_colors"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'text_colors' ? 'primary--text' : '',
                ],on:{"click":function($event){return _vm.handleClick('text_colors')}}},[_vm._v(" "+_vm._s(this.$t("tablebee.lang_text_settings"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'logo' ? 'primary--text' : '',
                ],on:{"click":function($event){return _vm.handleClick('logo')}}},[_vm._v(" "+_vm._s(this.$t("Logo"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'slideshow' ? 'primary--text' : '',
                ],on:{"click":function($event){return _vm.handleClick('slideshow')}}},[_vm._v(" "+_vm._s(this.$t("generic.lang_slideShow"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'messages' ? 'primary--text' : '',
                ],on:{"click":function($event){return _vm.handleClick('messages')}}},[_vm._v(" "+_vm._s(this.$t("generic.lang_gui_messages"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'general_settings'
                    ? 'primary--text'
                    : '',
                ],on:{"click":function($event){return _vm.handleClick('general_settings')}}},[_vm._v(" "+_vm._s(this.$t("generic.lang_globalSettings"))+" ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"9"}},[_c('v-card',[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[[_c('div',{attrs:{"role":"tablist"}},[(this.currentTab === 'colors')?_c('price-check-colors-component',{attrs:{"colors":_vm.template.theme_colors}}):_vm._e(),(this.currentTab === 'text_colors')?_c('price-check-text-colors-component',{attrs:{"texts":_vm.template.text_settings}}):_vm._e(),(this.currentTab === 'logo')?_c('price-check-logo-component',{attrs:{"logo":_vm.template.logo},on:{"getTemplate":_vm.getTemplate}}):_vm._e(),(this.currentTab === 'slideshow')?_c('price-check-slideshow-component',{attrs:{"slideshow_images":_vm.template.slideshow_images},on:{"getTemplate":_vm.getTemplate}}):_vm._e(),(this.currentTab === 'messages')?_c('price-check-messages-component',{attrs:{"messages":_vm.template.messages}}):_vm._e(),(this.currentTab === 'general_settings')?_c('price-check-general-settings-component',{attrs:{"settings":_vm.template.settings}}):_vm._e()],1)]],2)],1)],1)],1)],1)],1)],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.dismissOnScreenKeyboard),expression:"dismissOnScreenKeyboard"}],attrs:{"id":"onScreenKeyboardDiv"}},[(_vm.touchKeyboard.visible)?_c('vue-touch-keyboard',{staticClass:"internalWidth",attrs:{"accept":_vm.hideTouchKeyboard,"cancel":_vm.hideTouchKeyboard,"defaultKeySet":_vm.touchKeyboard.keySet,"input":_vm.touchKeyboard.input,"layout":_vm.touchKeyboard.layout,"options":_vm.touchKeyboard.options,"id":"onScreenKeyboard"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }