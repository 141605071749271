<template>
  <v-dialog persistent :value="showDialog" max-width="700">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("generic.lang_uploadImage") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog" :disabled="loading">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <Imagecropper
          v-if="showDialog"
          ref="cropper"
          v-model="image"
        ></Imagecropper>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="error" @click="closeDialog" :disabled="loading">
          {{ $t("generic.lang_cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="uploadImage"
          :loading="loading"
          :disabled="loading"
        >
          {{ $t("generic.lang_hochladen") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Imagecropper from "@/components/common/imagecropper";
import { ENDPOINTS } from "@/config";

export default {
  name: "TemplateImageUploadDialog",

  components: {
    Imagecropper,
  },

  props: {
    showDialog: Boolean,
  },

  data() {
    return {
      loading: false,
      image: null,
    };
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.image = null;
      }
    },
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    uploadImage() {
      this.loading = true;

      this.$refs.cropper.cropImage();

      this.$nextTick().then(() => {
        this.axios
          .post(
            ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATE.SLIDESHOWIMAGE
              .CREATE,
            {
              templateID: this.$route.params.id,
              uploadImage: this.image,
            }
          )
          .then((res) => {
            if (res.data.success) {
              this.$emit("imageUpload");
            }
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>
