<template>
  <b-card no-body class="mb-1 transparent">
    <b-card-header
      header-tag="header"
      v-b-toggle.accordion3
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'p-1']"
      role="tab"
    >
      <v-row>
        <v-col>
          <div class="pa-5 d-block">
            {{ "Logo" }}
          </div>
        </v-col>
      </v-row>
    </b-card-header>

    <b-card-body>
      <v-row>
        <v-col cols="12">
          <div
            :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
            class="main-card mb-3 card shadow-sm"
          >
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ "Logo" }}
              </div>
            </div>
            <div class="card-body">
              <image-cropper ref="logo" v-model="logoUrl" />
            </div>
            <div class="d-block text-right card-footer pa-0">
              <v-btn
                :disabled="loading"
                :loading="loading"
                @click="upload()"
                block
                :class="[this.$vuetify.theme.dark ? '' : 'text-dark']"
                class="btn bg-muted mx-auto mt-0 mb-0"
                large
              >
                <v-icon class="ma-1">cloud_upload</v-icon>
                {{ this.$t("generic.lang_hochladen") }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { mapState } from "vuex";
import ImageCropper from "@/components/common/imagecropper";

export default {
  name: "priceCheckLogoComponent",
  components: {
    ImageCropper,
  },
  props: {
    logo: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  data() {
    return {
      loading: false,
      logoo: "",
    };
  },
  computed: {
    ...mapState(["api"]),
    logoUrl: {
      get() {
        if (this.logo === null) return "";

        return (
          "https://img.3pos.de/unsafe/billsys_" +
          this.api.auth.posID +
          "/original/" +
          this.logo +
          ".png"
        );
      },
      set(val) {
        this.logoo = val;
      },
    },
  },
  methods: {
    upload() {
      this.$refs["logo"].cropImage();
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATE.LOGO, {
          templateID: this.$route.params.id,
          uploadImage: this.logoo,
        })
        .then((res) => {
          if (res.data.success) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });

            this.$emit("getTemplate");
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || res.data.status,
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
