<template>
  <b-card no-body class="transparent">
    <b-card-header
      header-tag="header"
      v-b-toggle.accordion3
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'p-1']"
      role="tab"
    >
      <v-row>
        <v-col>
          <div class="pa-5 d-block">
            {{ $t("settings.lang_slideshow_images") }}
          </div>
        </v-col>

        <v-col align="end" class="pa-5"> </v-col>
      </v-row>
    </b-card-header>

    <b-card-body :disabled="loading" :loading="loading">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-btn
              depressed
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="showImageUploadDialog = true"
            >
              {{ $t("generic.lang_uploadImage") }}
            </v-btn>
          </v-col>

          <v-col
            v-for="image in slideshow_images"
            :key="image"
            class="d-flex child-flex"
            cols="4"
          >
            <TemplateSlideshowImage
              @imageDelete="imageDeleted"
              :image="image"
            ></TemplateSlideshowImage>
          </v-col>
        </v-row>
      </v-container>
    </b-card-body>

    <TemplateImageUploadDialog
      :show-dialog="showImageUploadDialog"
      @closeDialog="showImageUploadDialog = false"
      @imageUpload="imageUploaded"
    ></TemplateImageUploadDialog>
  </b-card>
</template>

<script>
import TemplateSlideshowImage from "./slideshow/TemplateSlideshowImage";
import TemplateImageUploadDialog from "./slideshow/TemplateImageUploadDialog";

export default {
  name: "priceCheckSlideshowComponent",
  components: {
    TemplateImageUploadDialog,
    TemplateSlideshowImage,
  },
  props: {
    slideshow_images: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      showImageUploadDialog: false,
    };
  },

  methods: {
    imageUploaded() {
      this.showImageUploadDialog = false;
      this.$emit("getTemplate");
    },
    imageDeleted() {
      this.$emit("getTemplate");
    },
  },
};
</script>
