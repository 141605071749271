<template>
  <v-card flat tile>
    <v-img :src="imageUrl" aspect-ratio="1" class="grey lighten-2">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-card-actions>
      <v-btn
        @click="deleteImage"
        :loading="loading"
        :disabled="loading"
        color="error"
        text
      >
        <v-icon>delete</v-icon> {{ $t("generic.lang_delete") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { ENDPOINTS } from "@/config";

export default {
  name: "TemplateSlideshowImage",

  props: {
    image: String,
  },

  computed: {
    ...mapState(["api"]),
    imageUrl() {
      return (
        "https://img.3pos.de/unsafe/250x250/billsys_" +
        this.api.auth.posID +
        "/original/" +
        this.image +
        ".png"
      );
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    deleteImage() {
      this.loading = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATE.SLIDESHOWIMAGE.DELETE,
          {
            templateID: this.$route.params.id,
            imageUID: this.image,
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.$emit("imageDelete");
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
