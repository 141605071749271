<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title
      class="card-header card-header-tab bg-white border-0 transparent"
    >
      <div
        :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
        class="card-title pt-3"
      >
        {{ $t("generic.lang_globalSettings") }}
      </div>
    </v-card-title>

    <v-divider class="ma-0" />

    <v-card-text>
      <v-form ref="form" v-model="valide" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12">
              <p>Slideshow</p>
              <v-text-field
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                @focus="showTouchKeyboard"
                @keypress="acceptOnlyPositiveNumbers"
                autocomplete="off"
                :label="$t('generic.lang_durationInSeconds')"
                dense
                outlined
                min="0"
                type="number"
                v-model="settings.slideshowDuration"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <p>{{ $t("generic.lang_productDisplay") }}</p>
              <v-text-field
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                @focus="showTouchKeyboard"
                @keypress="acceptOnlyPositiveNumbers"
                autocomplete="off"
                :label="$t('generic.lang_durationInSeconds')"
                dense
                outlined
                min="0"
                type="number"
                v-model="settings.productDisplayDuration"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="border-top">
      <v-spacer />
      <v-btn :disabled="loading || !valide" @click="update()" color="success">
        {{ $t("generic.lang_apply") }}
      </v-btn>
    </v-card-actions>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        v-if="touchKeyboard.visible"
        id="onScreenKeyboard"
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
      />
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

export default {
  name: "priceCheckGeneralSettingsComponent",
  mixins: [mixin],
  props: {
    settings: {
      type: Object,
      required: false,
      // default: () => {},
      default() {
        return {
          slideshowDuration: 0,
          productDisplayDuration: 0,
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      valide: false,
    };
  },
  methods: {
    acceptOnlyPositiveNumbers(event) {
      if (isNaN(event.key)) event.preventDefault();
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATE.GENERAL_SETTINGS,
          {
            templateID: this.$route.params.id,
            settings: JSON.stringify(this.settings),
          }
        )
        .then((res) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_updatedSuccessfully"),
            color: "success",
          });
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
