<template>
  <div>
    <b-card no-body class="transparent">
      <b-card-header
        header-tag="header"
        v-b-toggle.accordion4
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'p-1']"
        role="tab"
      >
        <v-row>
          <v-col>
            <div class="pa-5 d-block">
              {{ this.$t("generic.lang_gui_messages") }}
            </div>
          </v-col>

          <v-col align="end" class="pa-5">
            <v-btn
              depressed
              color="success"
              @click="updateMessages"
              :loading="loadingLayout"
            >
              {{ $t("generic.lang_save") }}
            </v-btn>
          </v-col>
        </v-row>
      </b-card-header>

      <b-card-body>
        <b-tabs v-if="this.isTranslationEnabled">
          <b-tab active>
            <template #title>
              <v-btn text class="ma-0">
                <country-flag country="DE" />
              </v-btn>
            </template>

            <v-row class="pa-0" no-gutters>
              <v-col class="" cols="12">
                <v-subheader>
                  <strong> Nachricht auf der Willkommensseite </strong>
                </v-subheader>
                <quill-editor
                  id="editor1"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :options="quillOptions"
                  @focus="showTouchKeyboard"
                  output="html"
                  class="mb-10"
                  v-model="messages.welcomeMsg"
                >
                </quill-editor>
              </v-col>
            </v-row>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn text class="ma-0">
                <country-flag country="GB" />
              </v-btn>
            </template>

            <v-row class="pa-0" no-gutters>
              <v-col class="" cols="12">
                <v-subheader><strong>Welcome Page Message</strong></v-subheader>
                <quill-editor
                  id="editor3"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :options="quillOptions"
                  @focus="showTouchKeyboard"
                  output="html"
                  class="mb-10"
                  v-model="messages.welcomeMsg_en"
                >
                </quill-editor>
              </v-col>
            </v-row>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn text class="ma-0">
                <country-flag country="FR" />
              </v-btn>
            </template>

            <v-row class="pa-0" no-gutters>
              <v-col class="" cols="12">
                <v-subheader>
                  <strong> Message de la page de bienvenue </strong>
                </v-subheader>
                <quill-editor
                  id="editor5"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :options="quillOptions"
                  @focus="showTouchKeyboard"
                  output="html"
                  class="mb-10"
                  v-model="messages.welcomeMsg_fr"
                >
                </quill-editor>
              </v-col>
            </v-row>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn text class="ma-0">
                <country-flag country="SA" />
              </v-btn>
            </template>

            <v-row class="pa-0 text-right" no-gutters>
              <v-col class="" cols="12">
                <v-subheader><strong>رسالة صفحة الترحيب</strong></v-subheader>
                <quill-editor
                  id="editor7"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :options="quillOptions"
                  @focus="showTouchKeyboard"
                  output="html"
                  class="mb-10"
                  v-model="messages.welcomeMsg_ar"
                >
                </quill-editor>
              </v-col>
            </v-row>
          </b-tab>
        </b-tabs>

        <v-row v-else class="pa-0" no-gutters>
          <v-col class="" cols="12">
            <v-subheader>
              <strong>
                {{ $t("generic.lang_messageOnTheWelcomePage") }}
              </strong>
            </v-subheader>
            <quill-editor
              id="editor1"
              :data-layout="KEYBOARD.KEYSETS.NORMAL"
              :options="quillOptions"
              @focus="showTouchKeyboard"
              output="html"
              class="mb-10"
              v-model="messages.welcomeMsg"
            >
            </quill-editor>
          </v-col>
        </v-row>
      </b-card-body>
    </b-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { createNamespacedHelpers } from "vuex";
import CountryFlag from "vue-country-flag";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { quillOptions } from "@/plugins/quillSetup.js";

export default {
  name: "priceCheckMessagesComponent",
  mixins: [mixin],
  components: {
    CountryFlag,
    quillEditor,
  },
  props: {
    messages: {
      type: Object,
      required: false,
      // default: () => {},
      default() {
        return {
          welcomeMsg: "",
          welcomeMsg_en: "",
          welcomeMsg_fr: "",
          welcomeMsg_ar: "",
        };
      },
    },
  },
  data() {
    return {
      loadingLayout: false,
      quillOptions: quillOptions,
    };
  },
  computed: {
    ...createNamespacedHelpers("settings").mapGetters(["getSettingValue"]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    },
  },
  methods: {
    updateMessages() {
      this.loadingLayout = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATE.MESSAGES, {
          templateID: this.$route.params.id,
          messages: this.messages,
        })
        .then((res) => {
          if (res.status === 200) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loadingLayout = false;
        });
    },
  },
};
</script>

<style scoped>
.quill-editor {
  height: 300px !important;
}
</style>
